<template>
  <StaticPagePug>
    <div class="form-wrapper">
      <h2>{{ $t('resetPassword.header') }}</h2>
      <div class="resetPassword-box" v-show="isAuthorized">
        <div class="form-box" v-show="formFlag">
          <el-form
            label-position="top"
            :model="ruleResetPasswordForm"
            :rules="rulesResetPassword"
            ref="ruleResetPasswordForm"
          >
            <el-form-item :label="$t('changePw.field.newPw')" prop="pass">
              <el-input type="password" v-model="ruleResetPasswordForm.pass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$t('changePw.field.confirmPw')" prop="checkPass">
              <el-input type="password" v-model="ruleResetPasswordForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <p :class="{ isError: isError }">
              {{ $t('common.formValidation.newPwFormat') }}
            </p>
            <el-button @click.native="submitForm('ruleResetPasswordForm')" data-testid="resetPwd">
              {{ $t('common.button.submit') }}
            </el-button>
          </el-form>
        </div>
        <div class="reset-success" v-show="successFlag">
          <img src="@/assets/images/dialog_success.png" alt />
          <p>{{ $t('resetPassword.succ2') }}</p>
          <router-link :to="{ name: 'login' }" class="el-button">{{ $t('common.button.login') }}</router-link>
        </div>
      </div>
      <p class="no-authroization" v-show="isNotAuthroized">{{ $t('resetPassword.failed') }}</p>
    </div>
  </StaticPagePug>
</template>

<script>
import StaticPagePug from '@/components/template/staticPage/StaticPagePug';
import { md5 } from '@/util/encrypt';
import { apiCheckResetPasswordAuthorization, apiSubmitResetProfilePassword } from '@/resource';
import passwordCheckMixin from '@/mixins/passwordCheck';
import { validatePass } from '@/util/validation';

export default {
  mixins: [passwordCheckMixin],
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleResetPasswordForm.pass) {
        this.isError = true;
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      } else {
        this.isError = false;
        callback();
      }
    };
    return {
      isAuthorized: false,
      isNotAuthroized: true,
      formFlag: true,
      successFlag: false,
      isError: false,
      ruleResetPasswordForm: {
        pass: '',
        checkPass: '',
        token: ''
      },
      rulesResetPassword: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      }
    };
  },
  components: { StaticPagePug },
  mounted() {
    const token = this.$route.query.token;
    this.verifyAuthorization(token);
  },
  methods: {
    verifyAuthorization(token) {
      apiCheckResetPasswordAuthorization({
        token: this.$route.query.token
      }).then(resp => {
        if (resp.data.code == 0) {
          this.ruleResetPasswordForm.token = resp.data.data;
          this.isAuthorized = true;
          this.isNotAuthroized = !this.isAuthorized;
        } else {
          this.isAuthorized = false;
          this.$router.push('/login');
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          apiSubmitResetProfilePassword({
            token: this.ruleResetPasswordForm.token,
            pass: md5(this.ruleResetPasswordForm.pass),
            checkPass: md5(this.ruleResetPasswordForm.checkPass)
          }).then(resp => {
            if (resp.data.code == 0 && resp.data.data.result == true) {
              this.formFlag = false;
              this.successFlag = true;
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/resetProfilePasswordPug.scss';
</style>
