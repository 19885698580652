<template>
  <div id="loginClient">
    <div class="banner">
      <div class="img"></div>
      <div class="login-inner">
        <slot></slot>
        <div class="footer">
          <h1 class="socialMediaTitle">{{ $t('common.socialMedia.title') }}</h1>
          <SocialMedia></SocialMedia>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/components/template/staticPage/staticPagePug.scss';
</style>

<script>
import SocialMedia from '@/components/SocialMedia';

export default {
  name: 'StaticPagePug',
  components: { SocialMedia }
};
</script>
